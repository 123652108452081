import ApiService from "./api.service";

export function getList(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpot/list", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getMyFavoriteList(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpot/getMyFavoriteList", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function like(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpot/like", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function unlike(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpot/unlike", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function favorite(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpot/favorite", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function unfavorite(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/scenicSpot/unfavorite", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
