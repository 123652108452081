import ApiService from "./api.service";

export function getOption(option_name) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/getOption", { option_name })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getAgreement() {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/getAgreement")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getPrivacy() {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/getPrivacy")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getServiceHotline() {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/getServiceHotline")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
